.button {
  position: relative;
  overflow: hidden;
  transition: box-shadow 0.5s ease;
  text-align: center;
  font-family: var(--space-grotesk-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 18px 36px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  white-space: nowrap;
  @media (max-width: 768px) {
    line-height: 9.5px;
    border-radius: 1px;
    padding: 16px;
  }
}

.primary {
  position: relative;
  overflow: hidden;
  transition: box-shadow 0.5s ease;
  text-align: center;
  font-family: var(--space-grotesk-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 18px 36px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  white-space: nowrap;
  @media (max-width: 768px) {
    line-height: 9.5px;
    border-radius: 1px;
    padding: 16px;
  }
  color: var(--gray-00-white, #fff);
  border: none;
  background: linear-gradient(
    117deg,
    #fdd518 -25.4%,
    #fd9b05 17.68%,
    #c32900 94.05%,
    #fb5500 170.42%
  );
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1),
    0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      105deg,
      #fdd518 11.83%,
      #fd9b05 53.97%,
      #c32900 128.66%,
      #fb5500 203.35%
    );
    opacity: 0;
    transition: opacity 0.5s ease;
    z-index: 0;
  }
  &:hover:before {
    opacity: 1;
  }
  > span {
    position: relative;
    z-index: 1;
  }
}

.secondary {
  position: relative;
  overflow: hidden;
  transition: box-shadow 0.5s ease;
  text-align: center;
  font-family: var(--space-grotesk-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 18px 36px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  white-space: nowrap;
  @media (max-width: 768px) {
    line-height: 9.5px;
    border-radius: 1px;
    padding: 16px;
  }
  color: var(--Gray-Special, #222225);
  border: 1px solid var(--gray-300, #d4d4d8);
  background: var(--gray-00-white, #fff);
  &:hover {
    border: 1px solid var(--Gradient-1, #fdd518);
    span {
      background: var(
        --Gradient-1,
        linear-gradient(
          97deg,
          #fdd518 -10.55%,
          #fd9b05 6.86%,
          #c32900 44.47%,
          #fb5500 101.74%
        )
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.tertiary {
  position: relative;
  overflow: hidden;
  transition: box-shadow 0.5s ease;
  text-align: center;
  font-family: var(--space-grotesk-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 18px 36px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  white-space: nowrap;
  @media (max-width: 768px) {
    line-height: 9.5px;
    border-radius: 1px;
    padding: 16px;
  }
  color: var(--gray-00-white, #fff);
  border: none;
  background: #18181b;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1),
    0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(
      --gradient-2,
      linear-gradient(97deg, #c32900 -5.49%, #fb5500 68.62%)
    );
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1),
      0px 1px 2px 0px rgba(0, 0, 0, 0.06);
    opacity: 0;
    transition: opacity 0.5s ease;
    z-index: 0;
  }
  &:hover:before {
    opacity: 1;
  }
  > span {
    position: relative;
    z-index: 1;
  }
}
