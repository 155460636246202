.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  cursor: pointer;
}

.imageWrapper {
  position: relative;
}
.imageDesc {
  position: absolute;
  left: 10px;
  bottom: 10px;
  color: var(--Orange-4, #fdba74);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  border-radius: 72px;
  border: 1px solid rgba(251, 146, 60, 0.6);
  background: rgba(247, 246, 238, 0.01);
  backdrop-filter: blur(8px);
  padding: 10px;
}

.description {
  color: var(--Orange-3, #fb923c);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 18px */
}

.title {
  color: var(--Gray-8, #222225);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 30px */
  letter-spacing: -0.4px;
  max-width: 400px;
}

.title:hover {
  text-decoration: underline;
}
