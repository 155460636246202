.container {
  background: #18181b;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 95px 0 132px 16px;
}

.title {
  font-family: Sora;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -3.6px;
  background: var(
    --gradient-2,
    linear-gradient(97deg, #c32900 -5.49%, #fb5500 68.62%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  & span {
    -webkit-text-fill-color: initial;
  }
}

.contentWrapper {
  display: flex;
  align-items: center;
}

.description {
  display: flex;
  max-width: 387px;
  min-width: 263px;
  padding: 58px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  background: #fff;

  & p {
    color: var(--gray-700, #3f3f46);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
  }
}

@media (max-width: 1164px) {
  .container {
    justify-content: space-between;
  }

  .wrapper {
    padding: 60px 16px 26px;
  }

  .contentWrapper {
    flex-direction: column;
    align-items: stretch;
    gap: 32px;
    overflow: hidden;
  }

  .description {
    align-items: center;
    order: 1;
    padding: 50px 40px;
  }
}

.rankingWrapper span {
  font-size: 24px !important;
}

.buttonWrapper {
  width: 100%;
  button {
    width: 100%;
    padding: 1.5rem;
  }
}

@media (max-width: 768px) {
  .container {
    justify-content: center;
  }

  .wrapper {
    width: 100%;
    gap: 35px;
    padding: 60px 16px 26px;
  }

  .title {
    font-size: 32px;
    letter-spacing: -1.92px;
  }

  .image {
    display: none;
  }
}
