.container {
  background: #18181b;
  display: flex;
  width: 100%;
  height: 377px;
  gap: 64px;
  @media (max-width: 768px) {
    flex-direction: column;
    height: 100%;
    padding: 1rem;
    gap: 0;
    height: 100%;
  }
}

.title {
  margin-top: 70px;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -3.6px;
  background: var(
    --Gradient-1,
    linear-gradient(
      97deg,
      #fdd518 -10.55%,
      #fd9b05 6.86%,
      #c32900 44.47%,
      #fb5500 101.74%
    )
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media (max-width: 768px) {
    margin-top: 1rem;
    font-size: 42px;
    letter-spacing: -2.52px;
  }
}

.subtitle {
  color: var(--Orange-3, #fb923c);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;

  letter-spacing: -0.36px;
}

.partnersWrapper {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  gap: 35px;
  align-items: center;
  justify-content: center;
  padding-bottom: 60px;

  @media (max-width: 768px) {
    gap: 24px;
  }
}
