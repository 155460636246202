.container {
  background: #f7f6ee;
  display: flex;
  padding-top: 100px;
  padding-bottom: 140px;
  @media (max-width: 768px) {
    display: block;
  }
}

.wrapper {
  display: flex;
  gap: 40px;
  @media (max-width: 768px) {
    img {
      width: 119px;
      height: 287px;
    }
    flex-direction: column;
    gap: 1rem;
  }
}

.header {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.title {
  font-family: Sora;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 60px */
  letter-spacing: -3.6px;
  background: var(
    --Gradient-1,
    linear-gradient(
      97deg,
      #fdd518 -10.55%,
      #fd9b05 6.86%,
      #c32900 44.47%,
      #fb5500 101.74%
    )
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  max-width: 666px;
  padding-bottom: 5px;
  @media (max-width: 768px) {
    font-size: 32px;
    letter-spacing: -1.92px;
    margin-top: 1rem;
  }
  p {
    display: inline;
    background: transparent;
    -webkit-text-fill-color: initial;
  }
}

.subtitle {
  color: var(--Orange-3, #fb923c);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 21.6px */
  letter-spacing: -0.36px;
  margin-bottom: 2rem;
  @media (max-width: 768px) {
    margin-top: 1rem;
  }
}

.feedbackWrapper {
  display: flex;
  gap: 41px;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 1rem;
  }
}

.feedbackSection {
  @media (max-width: 768px) {
    display: flex;
    margin-bottom: 1rem;
  }
}

.feedbackMobileLine {
  display: flex;
  flex-wrap: nowrap;
  gap: 1rem;
  min-width: 100%;
}
