.container {
  background: #f7f6ee;
  display: flex;
  padding-top: 160px;
  h4 {
    max-width: 500px;
    text-align: left;
  }
  @media (max-width: 768px) {
    padding-top: 26px;
    flex-direction: column;
  }
  img {
    width: 100%;
  }
}

.rightSection {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.faqWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  gap: 150px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 60px;
  }
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 3rem 3rem 3rem;
  @media (max-width: 768px) {
    padding: 0;
    margin-top: 40px;
  }
}

.title {
  font-family: Sora;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -3.6px;
  background: var(
    --Gradient-1,
    linear-gradient(
      97deg,
      #fdd518 -10.55%,
      #fd9b05 6.86%,
      #c32900 44.47%,
      #fb5500 101.74%
    )
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media (max-width: 768px) {
    font-size: 42px;
    letter-spacing: -2.52px;
  }
}

.subtitle {
  text-align: center;
  color: var(--Orange-3, #fb923c);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.card {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.avatar {
  width: 74px;
  height: 74px;
  border-radius: 50%;
  background: url('/images/jakub-wasowski.jpg') lightgray 50% / cover
    no-repeat;
  @media (max-width: 768px) {
    width: 57px;
    height: 57px;
  }
}

.name {
  color: var(--Gray-9, #3f3f46);
  font-family: Sora;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  letter-spacing: -1px;
  @media (max-width: 768px) {
    font-size: 16px;
    letter-spacing: -0.8px;
  }
}

.email {
  color: var(--Orange-3, #fb923c);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.4px;
  @media (max-width: 768px) {
    font-size: 14px;
    letter-spacing: -0.28px;
  }
}

.image {
  margin-left: -3rem;
  width: 600px;
  height: 466px;
  background: url('/images/landing-v2/faq-image.webp') lightgray 50% /
    cover no-repeat;
  @media (max-width: 768px) {
    // display: none;
  }
}
