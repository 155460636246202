.eventSummary {
  width: 100%;
  background: radial-gradient(
    156.47% 154.1% at 82.64% 15.62%,
    #e06f08 0%,
    #c95201 0.01%,
    rgba(224, 111, 8, 0) 100%
  );
  @media (max-width: 768px) {
    width: 100%;
    z-index: 1;
    padding-top: 1rem;
  }
}

.eventSummaryTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 180px;
  text-align: center;
  font-family: Sora;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 90%;
  letter-spacing: -3px;
  background: var(
    --Gradient-1,
    linear-gradient(
      97deg,
      #fdd518 -10.55%,
      #fd9b05 6.86%,
      #c32900 44.47%,
      #fb5500 101.74%
    )
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 110%;
    letter-spacing: -1.44px;
    padding: 0 0.5rem;
  }
}

.imageWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.image {
  width: 707px;
  height: 615px;
  background: url('/images/landing-v2/event-summary.webp') lightgray
    50% / cover no-repeat;
}

.imageMobile {
  width: 100%;
  height: 251px;
  background: url('/images/landing-v2/event-summary-mobile.webp')
    lightgray 50% / cover no-repeat;
  margin-top: 30px;
}

.timerScaleWrapper > div {
  scale: 0.5;
  margin: -1rem;
  @media (max-width: 768px) {
    scale: 1;
    margin: 1rem;
  }
}

.counterBox {
  position: absolute;
  top: 70px;
  z-index: 4;
  width: 591px;
  min-height: 179px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fff;
  padding: 2rem 0;
  @media (max-width: 768px) {
    position: relative;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 24px;
    z-index: 1;
    margin: 16px 0;
    padding-bottom: 40px;
  }
}

.timerTextWrapper {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
  margin-right: -16%;
  @media (max-width: 768px) {
    flex-direction: column;
    margin: 0;
    gap: 1rem;
  }
}

.timerText {
  color: var(--gray-700, #3f3f46);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  white-space: nowrap;
  margin-right: -110px;
  @media (max-width: 768px) {
    margin: 0;
  }
}

.timerHeader {
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -2.88px;
  background: var(
    --Gradient-1,
    linear-gradient(
      97deg,
      #fdd518 -10.55%,
      #fd9b05 6.86%,
      #c32900 44.47%,
      #fb5500 101.74%
    )
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media (max-width: 768px) {
    text-align: left;
    font-size: 50px;
    letter-spacing: -3px;
    p {
      line-height: 90%;
    }
  }
}

.descriptionBox {
  position: absolute;
  top: 278px;
  z-index: 3;
  width: 493px;
  height: 336px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fff;
  padding: 3rem;
  gap: 20px;
  @media (max-width: 768px) {
    position: relative;
    top: 0;
    padding: 2rem;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}

.description {
  color: var(--gray-700, #3f3f46);
  font-family: Sora;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  @media (max-width: 768px) {
    font-size: 18px;
  }
}

.descriptionBold {
  color: var(--gray-700, #3f3f46);
  font-family: Sora;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  @media (max-width: 768px) {
    font-size: 18px;
  }
}

.infoSection {
  position: relative;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8rem;
  width: 100%;
  button {
    padding: 0 7rem;
    height: 105px;
    font-size: 20px;
    @media (max-width: 768px) {
      margin-top: 100px;
      width: 100%;
      font-family: 'Space Grotesk';
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 1rem;
  }
}

.infoBox {
  background: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 1.5rem;
  @media (max-width: 768px) {
    width: 100%;
    text-align: left;
    align-items: flex-start;
  }
}

.infoBoxMobile {
  background: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 30px 20px;
  gap: 9px;
  width: 50%;
}

.mobileWrapper {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
}
.infoBoxTitle {
  color: var(--Gray-6, #1e293b);
  text-align: left;
  font-family: Inter;
  font-size: 14.645px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21.967px */
  align-self: flex-start;
  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.infoBoxContent {
  color: #000;
  text-align: center;
  font-family: Sora;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  white-space: nowrap;
  @media (max-width: 768px) {
    color: #000;
    font-family: Sora;
    font-size: 22px;
    white-space: wrap;
  }
}

.teamMatchingInfo {
  position: absolute;
  transform: rotate(-15deg);
  padding: 10px 20px;
  flex-direction: column;
  justify-content: center;
  border-radius: 1px;
  border: 5px solid #f7f6ee;
  background: #fff;
  left: 340px;
  top: 115px;
  @media (max-width: 768px) {
    left: 5px;
    top: 240px;
    width: 95%;
    transform: rotate(-8deg);
  }
  p {
    text-align: center;
    font-family: Sora;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 21px */
    background: var(
      --Gradient-1,
      linear-gradient(
        97deg,
        #fdd518 -10.55%,
        #fd9b05 6.86%,
        #c32900 44.47%,
        #fb5500 101.74%
      )
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  span {
    background: var(
      --Gradient-1,
      linear-gradient(
        97deg,
        #fdd518 -10.55%,
        #fd9b05 6.86%,
        #c32900 44.47%,
        #fb5500 101.74%
      )
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Sora;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}
