.footer {
  background: var(--gray-50, #fafafa);
  display: flex;
  justify-content: space-between;
  padding: 70px 0;
  @media (max-width: 768px) {
    padding-top: 80px;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 130px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 80px;
  }
}

.column {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.logoWrapper {
  max-width: 300px;
  @media (max-width: 768px) {
    max-width: none;
  }
}

.subheader {
  color: var(--gray-400, #a1a1aa);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.links {
  color: var(--gray-600, #52525b);
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.links:hover {
  color: #fd9b05;
  transition: 0.3s;
}

.mobileLogoWrapper {
  display: flex;
  margin-bottom: 20px;
  span {
    display: flex;
    margin-left: -20px;
    align-items: center;
  }
  div {
    margin-left: 10px;
    margin-top: -10px;
  }
  img {
    width: 124px;
    height: 24px;
  }
}

.mobileWrapper {
  display: flex;
  gap: 130px;
  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    gap: 0;
  }
}
