.container {
  background: #f7f6ee;
  padding-bottom: 180px;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-top: 40px;
  @media (max-width: 768px) {
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 400px;
    padding-top: 0px;
  }
}

.hero {
  z-index: 3;
  background-color: #ffffff;
  padding: 60px 40px;
  display: flex;
  flex-direction: column;
  max-width: 750px;
  gap: 12px;
  button {
    margin-top: 10px;
    max-width: 400px;
  }
  @media (max-width: 768px) {
    padding: 1rem;
  }
}

.title {
  font-family: Sora;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: 90%; /* 54px */
  letter-spacing: -3.6px;
  background: var(
    --gradient-2,
    linear-gradient(97deg, #c32900 -5.49%, #fb5500 68.62%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  span {
    line-height: 110%;
  }
  @media (max-width: 768px) {
    font-size: 42px;
    line-height: 90%;
    letter-spacing: -2.52px;
    display: inline;
  }
}

.subtitleWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  @media (max-width: 768px) {
    display: block;
  }
}

.subtitle {
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1.2px;
  background: var(
    --gradient-2,
    linear-gradient(97deg, #c32900 -5.49%, #fb5500 68.62%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 140%; /* 25.2px */
    letter-spacing: -1.08px;
    margin: 10px 0;
    svg {
      display: inline;
      margin-left: 5px;
      margin-top: -2px;
    }
  }
}

.nerdbord {
  color: var(--Gray-7, #0e1116);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 90%;
  letter-spacing: -1.2px;
  @media (max-width: 768px) {
    font-size: 18px;
    color: var(--Gray-7, #0e1116);
    line-height: 140%; /* 25.2px */
    letter-spacing: -1.08px;
    display: inline;
    background: transparent;
    -webkit-text-fill-color: initial;
  }
}

.description {
  color: var(--gray-700, #3f3f46);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  max-width: 450px;
}

.backgroundImageBig {
  position: absolute;
  top: -40px;
  left: 730px;
  z-index: 1;
  @media (max-width: 768px) {
    top: 660px;
    left: 0;
    width: 343px;
    height: 192px;
  }
}

.backgroundImageSmall {
  position: absolute;
  top: 110px;
  left: 900px;
  z-index: 2;
  @media (max-width: 768px) {
    display: none;
  }
}

.award1Wrapper {
  position: absolute;
  top: 222px;
  left: 500px;
  z-index: 8;
  transform: rotate(-10deg);
  @media (max-width: 768px) {
    top: 494px;
    left: -25px;
    border-radius: 4.148px;
    background: linear-gradient(133deg, #fff -23.28%, #fff 371.46%);
    box-shadow: 0px 10.486px 47.937px 0px rgba(251, 146, 60, 0.03),
      0px 2.996px 47.937px 0px rgba(251, 146, 60, 0.04);
  }
}

.award2Wrapper {
  position: absolute;
  top: 345px;
  left: 650px;
  z-index: 7;
  transform: rotate(7deg);
  @media (max-width: 768px) {
    top: 589px;
    left: 90px;
    transform: rotate(7deg);
    border-radius: 4.148px;
    background: linear-gradient(133deg, #fff -23.28%, #fff 371.46%);
    box-shadow: 0px 10.486px 47.937px 0px rgba(251, 146, 60, 0.03),
      0px 2.996px 47.937px 0px rgba(251, 146, 60, 0.04);
  }
  .image {
    transform: rotate(-20deg);
    overflow: hidden;
  }
}

.buttonWrapper {
  @media (max-width: 768px) {
    button {
      background-color: black;
      width: 100%;
      font-size: 14px;
      padding: 2rem 1rem;
    }
  }
}

.mobileLogoWrapper {
  display: inline;
  span {
    display: flex;
    align-items: center;
    gap: 2px;
  }
}
