.container {
  display: flex;
  justify-content: center;
  padding: 140px 0;
  background: #f7f6ee;
  width: 100%;
  @media (max-width: 768px) {
    padding: 60px 0;
    padding-bottom: 40px;
    overflow: hidden;
  }
}

.outsideLine {
  display: block;
  flex: 1;
  width: 100%;
  height: 1px;
  margin-top: 157px;
  background: var(--Orange-3, #fb923c);

  &:first-child {
    margin-right: -16px;
    @media (max-width: 768px) {
      display: none;
    }
  }

  &:last-child {
    margin-left: -16px;
    @media (max-width: 768px) {
      display: none;
    }
  }
}

.title {
  font-family: Sora;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -3.6px;
  background: var(
    --Gradient-1,
    linear-gradient(
      97deg,
      #fdd518 -10.55%,
      #fd9b05 6.86%,
      #c32900 44.47%,
      #fb5500 101.74%
    )
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media (max-width: 768px) {
    font-size: 42px;
    letter-spacing: -2.52px;
    line-height: 90%; /* 37.8px */
    padding-left: 20px;
  }
}

.scheduleList {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  width: 100%;
  margin-top: 56px;
  button {
    width: 100%;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 40px;
  }
}

.label {
  position: absolute;
  bottom: -25px;
  right: 225px;
  transform: rotate(-15deg);
  padding: 20px 40px;
  border-radius: 1px;
  border: 5px solid #f7f6ee;
  background: #fff;
  @media (max-width: 768px) {
    bottom: 355px;
    right: 25px;
  }
  & p {
    text-transform: uppercase;
    font-family: Sora;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    background: var(
      --Gradient-1,
      linear-gradient(
        97deg,
        #fdd518 -10.55%,
        #fd9b05 6.86%,
        #c32900 44.47%,
        #fb5500 101.74%
      )
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.mobileBox {
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}