.container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  padding: 40px;
  width: 280px;
}
.wrapper {
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text {
  color: var(--Gray-6, #1e293b);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 15px */
  max-width: 200px;

}
