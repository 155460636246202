.mainContainer {
  background: #f7f6ee;
}

.container {
  display: flex;
  justify-content: flex-end;
  @media (max-width: 768px) {
 display: block;
  }
}

.header {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 35px;
  h4 {
    max-width: 450px;
    text-align: left;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
    letter-spacing: -0.4px;
    margin-top: -20px;
    margin-bottom: 30px;
  }
}

.title {
  font-family: Sora;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -3.6px;
  background: var(
    --Gradient-1,
    linear-gradient(
      97deg,
      #fdd518 -10.55%,
      #fd9b05 6.86%,
      #c32900 44.47%,
      #fb5500 101.74%
    )
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  max-width: 600px;
  @media (max-width: 768px) {
    font-size: 40px;
    line-height: 90%;
    letter-spacing: -2.4px;
    padding-bottom: 15px;
  }
}

.titleMargin {
  margin-top: -35px;
  @media (max-width: 768px) {
   margin-top: 0;
  }
}

.cardsWrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  button {
    max-width: 350px;
  }
}

.rightSection {
  width: 740px;
  height: 1320px;
  background: url('/images/landing-v2/check-workshops.webp') lightgray
    50% / cover no-repeat;
}

.buttonWrapper {
  button {
    width: 100%;
    height: 70px;
    margin-bottom: 25px;
  }
}