.container {
  width: 280px;
  height: 161px;
  background-color: #ffffff;
  padding: 2rem;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.title {
  color: var(--gray-800, #27272a);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  margin-bottom: 15px;
  @media (max-width: 768px) {
    margin-bottom: 5px;
  }
}

.description {
  color: var(--gray-700, #3f3f46);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
