.container {
  background: #f7f6ee;
  width: 100%;
  position: relative;
  padding-top: 130px;
  overflow: hidden;
  @media (max-width: 768px) {
    padding-top: 30px;
  }
}

.title {
  font-family: Sora;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -3.6px;
  background: var(
    --Gradient-1,
    linear-gradient(
      97deg,
      #fdd518 -10.55%,
      #fd9b05 6.86%,
      #c32900 44.47%,
      #fb5500 101.74%
    )
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media (max-width: 768px) {
    font-family: Sora;
    font-size: 42px;
    letter-spacing: -2.52px;
  }
}

.subtitle {
  max-width: 750px;
  margin-top: 1rem;
  color: var(--Gray-3, #71717a);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.36px;
  @media (max-width: 768px) {
    font-size: 16px;
    letter-spacing: -0.32px;
  }
}

.labelRightWrapper {
  position: absolute;
  top: 160px;
  right: 0;
  @media (max-width: 768px) {
    top: 265px;
  }
}
.labelRightTop {
  z-index: 2;
  position: relative;
  font-family: Sora;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
  border-radius: 1px;
  background: #fff;
  width: 210px;
  height: 60px;
  transform: skew(-20deg) rotate(-25deg);

  p {
    position: absolute;
    top: 20px;
    left: 40px;
    transform: skew(20deg);
    background: var(
      --Gradient-1,
      linear-gradient(
        97deg,
        #fdd518 -10.55%,
        #fd9b05 6.86%,
        #c32900 44.47%,
        #fb5500 101.74%
      )
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.labelRightBottom {
  width: 200px;
  height: 60px;
  border-radius: 1px;
  transform: skew(26deg) rotate(45deg);
  z-index: 1;
  position: absolute;
  top: 121px;
  left: 14px;
  padding-top: 20px;
  padding-left: 50px;
  font-family: Sora;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  background: #f9f9f9;
  @media (max-width: 768px) {
    padding-left: 36px;
  }
  p {
    background: var(
      --Gradient-1,
      linear-gradient(
        97deg,
        #fdd518 -10.55%,
        #fd9b05 6.86%,
        #c32900 44.47%,
        #fb5500 101.74%
      )
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transform: skew(-16deg);
  }
}

.specializationWrapper {
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
  gap: 40px;
  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 125px;
  }
}
