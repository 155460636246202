.container {
  background: #f7f6ee;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 30px;
  overflow: hidden;
  @media (max-width: 768px) {
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 45px;
  }
}

.innerContainer {
  background: #f7f6ee;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-bottom: 80px;
  position: relative;
  @media (max-width: 768px) {
    padding: 0;
    padding-bottom: 3rem;
  }
}

.header {
  margin-top: 2rem;
  padding-right: 10px;
  span {
    margin-top: -70px;
    @media (max-width: 768px) {
      margin-top: -30px;
    }
  }
  display: flex;
  flex-direction: column;
  font-family: Sora;
  font-size: 110px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -6.3px;
  background: var(
    --Gradient-1,
    linear-gradient(
      97deg,
      #fdd518 -10.55%,
      #fd9b05 6.86%,
      #c32900 44.47%,
      #fb5500 101.74%
    )
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media (max-width: 768px) {
    font-size: 50px;
    letter-spacing: -3px;
    margin-top: 1rem;
  }
}
.headerWrapper {
  z-index: 3;
}
.subHeader {
  margin-top: 1rem;
  color: var(--Orange-3, #fb923c);
  font-family: Inter;
  font-size: 29px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.58px;
  max-width: 600px;
  @media (max-width: 768px) {
    font-size: 16px;
    letter-spacing: -0.32px;
    margin-top: 0;
  }
}

.image {
  position: absolute;
  top: -20px;
  left: 760px;
  width: 700px;
  height: 590px;
  z-index: 1;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    top: 200px;
    left: calc(50% - 50px);
  }
}

.buttonsWrapper {
  display: flex;
  gap: 20px;
  margin-top: 50px;
  @media (max-width: 768px) {
    margin-top: 150px;
    flex-direction: column;
    button {
      width: 220px;
      height: 70px;
      gap: 1px;
      font-size: 16px;
    }
  }
}

.iconsWrapper {
  margin-top: 150px;
  display: flex;
  justify-content: space-between;
  gap: 40px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 50px;
    margin-top: 50px;
  }
}

.icon1 {
  position: absolute;
  top: -150px;
  left: 15px;
  @media (max-width: 768px) {
    position: relative;
    top: 0;
    left: 0;
    width: 154px;
    height: 153px;
  }
}

.icon2 {
  position: absolute;
  top: -150px;
  left: 15px;
  @media (max-width: 768px) {
    position: relative;
    top: 0;
    left: 0;
    width: 148px;
    height: 148px;
  }
}

.icon3 {
  position: absolute;
  top: -160px;
  left: 15px;
  transform: perspective(1000px) rotateY(15deg);
  @media (max-width: 768px) {
    position: relative;
    top: 0;
    left: 0;
    width: 160px;
    height: 160px;
  }
}

.icon4 {
  position: absolute;
  top: -150px;
  left: 15px;
  @media (max-width: 768px) {
    position: relative;
    top: 0;
    left: 0;
    width: 174px;
    height: 174px;
  }
}
