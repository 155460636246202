.container {
  color: var(--gray-700, #3f3f46);
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%; /* 21px */
  width: 387px;
  padding: 40px;
  text-align: left;
  background-color: #ffffff;
  min-height: 100px;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    width: 283px;
    height: 164px;
    display: flex;
    align-items: center;
    justify-self: center;
  }
}
