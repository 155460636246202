.container {
  background: #f7f6ee;
  padding: 200px 0;
  @media (max-width: 768px) {
    padding-top: 50px;
    button {
      width: 100%;
      height: 70px;
      font-size: 16px;
    }
  }
}

.title {
  text-align: center;
  font-family: Sora;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -3.6px;
  background: var(
    --Gradient-1,
    linear-gradient(
      97deg,
      #fdd518 -10.55%,
      #fd9b05 6.86%,
      #c32900 44.47%,
      #fb5500 101.74%
    )
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 90%;
    letter-spacing: -1.92px;
  }
}

.subtitle {
  color: var(--Orange-3, #fb923c);
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.36px;
  @media (max-width: 768px) {
    font-size: 16px;
    letter-spacing: -0.32px;
  }
}

.counterWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  > h4 {
    max-width: 600px;
  }
}
