.container {
  width: 387px;
  height: 265px;
  background-color: #ffffff;
  padding: 3rem;
  @media (max-width: 768px) {
    padding: 2rem;
    width: 100%;
    height: 100%;
  }
}

.title {
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1.8px;
  background: var(
    --gradient-2,
    linear-gradient(97deg, #c32900 -5.49%, #fb5500 68.62%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
}

.description {
  color: var(--gray-700, #3f3f46);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}
