.rankingContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 554px;
  max-height: 1100px;
  overflow: auto;
}

.rankingHeader {
  display: flex;
  align-items: center;
  height: 41px;
  margin-bottom: 7px;
  background: #11100e;
  color: var(--Light-1, #fafafa);
  font-family: var(--space-grotesk-font);

  & p {
    padding: 6px 0;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
    border-right: 1px solid #fafafa;
  }

  & .headerFirst {
    width: 100px;
  }

  & .headerSecond {
    flex-grow: 1;
  }

  & .headerThird {
    width: 120px;
  }

  & .headerFourth {
    width: 120px;
    border-right: none;
  }
}

.rankingItem {
  cursor:pointer;
  display: flex;
  align-items: center;
  height: 54px;
  background: var(--Gray-8, #222225);
  &:nth-child(odd) {
    background: var(--Gray-9, #3f3f46);
  }

  & p,
  span {
    text-align: center;
    color: var(--Light-3, #f4f4f5);
    font-family: var(--space-grotesk-font);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 90%; /* 16.2px */
    letter-spacing: -1.08px;
  }

  & .itemFirst {
    width: 100px;
    font-size: 24px;
  }

  & .itemSecond {
    display: flex;
    align-items: center;
    gap: 25px;
    flex-grow: 1;
    padding: 0 25px;

    & img {
      border-radius: 50%;
      background-color: #ea580c;
      margin: 8px 0;
    }

    & p {
      font-size: 14px;
      max-width: 110px;
      overflow: hidden;
      -webkit-line-clamp: 1;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-wrap: break-word;
    }
    }
  

  & .itemThird,
  & .itemFourth {
    width: 120px;
  }
}

@media (max-width: 768px) {
  .container {
    overflow-x: auto;
  }

  .rankingItem {
      & .itemFirst {
        font-size: 16px;
      }
    }
}

.rankingHeaderMobile {
  display: flex;
  align-items: center;
  height: 41px;
  margin-bottom: 7px;
  background: #11100e;
  color: var(--Light-1, #fafafa);
  font-family: var(--space-grotesk-font);

  & p {
    padding: 6px 0;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    border-right: 1px solid #fafafa;
  }

  & .headerFirstMobile {
    padding: 5px 0px;
    width: 70px;
  }

  & .headerSecondMobile {
    width: 140px; 
    padding: 5px 0px;
    padding-right: 50px;
  }

  & .headerThirdMobile {
    padding: 5px 0px;
    width: 60px;
  }

  & .headerFourthMobile {
    padding: 5px 0px;
    width: 90px;
    border-right: none;
  }
}

.rankingItemMobile {
  display: flex;
  align-items: center;
  padding: 10px 0;
  background: var(--Gray-8, #222225);
  cursor:pointer
  &:nth-child(odd) {
    background: var(--Gray-9, #3f3f46);
  }

  & p,
  span {
    text-align: center;
    color: var(--Light-3, #f4f4f5);
    font-family: var(--space-grotesk-font);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 90%; /* 16.2px */
    letter-spacing: -1.08px;
  }

  & .itemFirstMobile {
    width: 70px;
    font-size: 16px;
    text-align: center;
  }

  &.firstItemMobile .itemFirstMobile {
    font-size: 16px;
  }

  & .itemSecondMobile {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 140px;
    text-align: center;

    & img {
      border-radius: 50%;
      background-color: #ea580c;
      width: 24px;
      height: 24px;
    }

    & p {
      font-size: 14px;
      max-width: 110px;
      overflow: hidden;
      -webkit-line-clamp: 1;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-wrap: break-word;
    }
  }

  & .itemThirdMobile {
    width: 60px;
    text-align: center;
  }
  & .itemFourthMobile {
    width: 90px;
  }
}

.rankingContainerMobile {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  max-height: 500px;
  overflow: auto;
}
