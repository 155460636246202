.container {
  background: #f7f6ee;
  width: 100%;
  padding: 200px 0;
  @media (max-width: 768px) {
    padding-top: 50px;
    padding-bottom: 100px;
  }
}

.header {
  display: flex;
  align-items: center;
  gap: 70px;
  margin-bottom: 40px;
  @media (max-width: 768px) {
  flex-direction: column;
  align-items: flex-start;
  margin-top: 60px;
  gap: 1rem;
  }
}

.title {
  padding-right: 5px;
  font-family: Sora;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -3.6px;
  background: var(
    --Gradient-1,
    linear-gradient(
      97deg,
      #fdd518 -10.55%,
      #fd9b05 6.86%,
      #c32900 44.47%,
      #fb5500 101.74%
    )
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media (max-width: 768px) {
    font-size: 42px;
    letter-spacing: -2.52px;
  }
}

.subtitle {
  color: var(--Orange-3, #fb923c);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.36px;
  max-width: 750px;
  @media (max-width: 768px) {
    font-size: 16px;
    letter-spacing: -0.32px;
  }
}

.wrapper {
  display: flex;
  gap: 40px;
}

.prizesWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
