.container {
  background: #f7f6ee;
  width: 100%;
  padding: 160px 0;
  @media (max-width: 768px) {
    padding: 50px 0;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  margin-bottom: 70px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
    margin-bottom: 1rem;
  }
}

.title {
  padding-right: 10px;
  white-space: nowrap;
  font-family: Sora;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -3.6px;
  background: var(
    --Gradient-1,
    linear-gradient(
      97deg,
      #fdd518 -10.55%,
      #fd9b05 6.86%,
      #c32900 44.47%,
      #fb5500 101.74%
    )
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media (max-width: 768px) {
    font-size: 42px;
    letter-spacing: -2.52px;
  }
}

.subtitle {
  color: var(--Orange-3, #fb923c);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.36px;
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 150%;
  }
}

.wrapper {
  display: flex;
  gap: 40px;
  position: relative;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2rem;
  }
}

.award1Wrapper {
  position: absolute;
  top: 0px;
  right: 0;
  transform: rotate(-9.531deg);
  right: 80px;
}

.award2Wrapper {
  position: absolute;
  top: 150px;
  right: 0;
  transform: rotate(4.02deg);
}
